module.exports = [{
      plugin: require('../node_modules/@gatsbywpthemes/gatsby-theme-wp-search/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@gatsbywpthemes/gatsby-plugin-wordpress-lightbox/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@gatsbywpthemes/gatsby-plugin-gwpt-packages/gatsby-browser.js'),
      options: {"plugins":[],"wordPressUrl":"https://admin.hustleheadquarters.com","pathPrefix":"","paginationPrefix":"page","addWordPressComments":true,"addWordPressSearch":false,"gaTrackingId":0,"gaOptions":{},"googleTagManagerId":0,"googleTagManagerOptions":{},"addSiteMap":true,"siteMapOptions":{},"widgetAreas":{"slideMenuWidgets":["Categories","RecentPosts","Tags","SocialFollow"],"sidebarWidgets":["Categories","RecentPosts","Tags","SocialFollow"]},"archiveSidebarPosition":"right","pageCreateDebugOutput":false,"logo":null,"darkModeLogo":null,"socialFollowLinks":[],"developLimit":null,"addColorModes":true,"webfontsOptions":{"fonts":{"google2":[{"family":"Bitter","fontDisplay":"swap","strategy":"cdn"},{"family":"Coda","fontDisplay":"swap","strategy":"cdn"},{"family":"Russo One","fontDisplay":"swap","strategy":"cdn"}]}},"gatsbySourceWordPressOptions":{"schema":{"timeout":3600000,"requestConcurrency":5}},"createPages":true,"createPosts":true,"createCategories":true,"createTags":true,"createUsers":true,"withApollo":true,"layoutWidth":{"page":"lg","post":"lg","archive":"lg"},"fonts":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://admin.hustleheadquarters.com/graphql","excludeFieldNames":["blocksJSON","saveContent"],"presets":[{"presetName":"DEVELOP","options":{"type":{"__all":{"limit":null},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true}}],"schema":{"timeout":3600000,"requestConcurrency":5,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","perPage":100,"previewRequestConcurrency":5},"verbose":true},
    },{
      plugin: require('../node_modules/@gatsbywpthemes/gatsby-theme-blog-data/gatsby-browser.js'),
      options: {"plugins":[],"gaTrackingId":0,"gaOptions":{},"googleTagManagerId":0,"googleTagManagerOptions":{},"siteMapOptions":{},"developLimit":null,"gatsbySourceWordPressOptions":{"schema":{"timeout":3600000,"requestConcurrency":5}},"createPages":true,"createPosts":true,"createCategories":true,"createTags":true,"createUsers":true,"withApollo":true,"addSiteMap":true,"webfontsOptions":{"fonts":{"google2":[{"family":"Bitter","fontDisplay":"swap","strategy":"cdn"},{"family":"Coda","fontDisplay":"swap","strategy":"cdn"},{"family":"Russo One","fontDisplay":"swap","strategy":"cdn"}]}},"layoutWidth":{"page":"lg","post":"lg","archive":"lg"},"wordPressUrl":"https://admin.hustleheadquarters.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
